<template>
  <div v-if="item" @click="handleClick(item.id)" class="cursor-pointer">
    <div class="a-salary-advance p-4 bg-white relative rounded-2xl shadow-2">
      <div class="flex flex-nowrap justify-between money-wrapper mb-2">
        <div
          class="money text-xl font-medium mb-1 whitespace-nowrap text-tertiary"
        >
          {{ numberToMoney(item.amount) }} VNĐ
        </div>
        <div class="text-right">
          <label
            class="border border-info whitespace-nowrap inline-block font-medium px-1 py-0.5 rounded text-center text-info text-sm"
            :class="
              `text-${calcColor(item.status)} border-${calcColor(item.status)}`
            "
            style="min-width: 60px"
          >
            {{ translateStatus(item.status) }}
          </label>
        </div>
      </div>
      <div class="fee-wrapper text-base flex justify-between">
        <div class="interest mb-1">
          Ngày tạo: {{ formatTime(item.createdAt) }}
        </div>
        <div v-if="item.term" class="time mb-1">
          Kỳ hạn: {{ item.term }} tháng
        </div>
      </div>
      <!-- <div
        v-if="!item.isSupported && item.type === 'LOAN'"
        class="fee-wrapper flex text-warning text-sm"
      >
        <span class="text-sm">
          *Với thông tin hiện tại, Interloan không thể đáp ứng nhu cầu của bạn,
          <span class="underline cursor-pointer">nhấp để xem chi tiết</span>
        </span>
      </div> -->
    </div>
  </div>
</template>
<script>
import { numberToMoney } from '@/helpers/currency';
import * as Helper from '@/helpers';
import constRouter from '@/constants/constRouter';
// import { setContractId } from '@/helpers/localStorage';
import { mapMutations } from 'vuex';

export default {
  name: 'SalaryAdvanceItem',
  components: {},
  props: {
    item: {
      type: Object,
    },
    ofUser: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      numberToMoney,
      Helper,
    };
  },
  methods: {
    ...mapMutations({
      setExigency: 'setExigency',
      setContractId: 'setContractId',
    }),
    formatTime(time) {
      const text = time.substr(0, 10); // 2021-01-18
      const array = text.split('-');
      return `${array[2]}/${array[1]}/${array[0]}`; // 18/01/2021
    },
    calcColor(key) {
      switch (key) {
        case 'NEW':
          return 'info';
        default:
          return 'success';
      }
    },
    translateStatus(key) {
      switch (key) {
        case 'NEW':
          return 'Khoản ứng mới';
        default:
          return key;
      }
    },
    handleClick(id) {
      this.$router.push({
        name: constRouter.DASHBOARD_SALARY_ADVANCE_ITEM.name,
        params: {
          id,
        },
      });
      // not support && type = loan
      // if (!this.item.isSupported && this.item.type === 'LOAN') {
      //   // store id to localstorage
      //   const { id, term, amount } = this.item;
      //   this.setContractId(id);

      //   // store contract data to store
      //   setContractId(id);
      //   this.setExigency({
      //     amount: amount,
      //     term: term,
      //   });
      //   this.$router.push({
      //     name: constRouter.BORROWER_CHOOSE_LOAN.name,
      //     params: {
      //       id,
      //     },
      //   });
      // } else {
      //   // view detail
      //   if (this.item.isSupported) {
      //     this.$router.push({
      //       name: constRouter.DASHBOARD_SALARY_ADVANCE_ITEM.name,
      //       params: {
      //         id,
      //       },
      //     });
      //   } else {
      //     this.$router.push({
      //       name: constRouter.DASHBOARD_SALARY_ADVANCE_ITEM_LEADGEN.name,
      //       params: {
      //         id,
      //       },
      //     });
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
